import React from "react"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-css"
import { useGallery } from "../hooks/use-gallery"
import Footer from "../components/footer"
import Headroom from "react-headroom"
import Reveal from "../components/reveal"
import { Helmet } from "react-helmet"


export default function Gallery() {
  const gallery = useGallery()
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  }
  console.log(gallery)
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="Description" content="Lance Gallery – Portfolio of an Independent Lettering and Brand Identity Designer"></meta>
      <title>Lance – Gallery</title>
      <link rel="preload" href="/fonts/fonts.css" as="style"></link>
    </Helmet>
    <header>
      <Headroom>
        <Header />
      </Headroom>
    </header>
    <main>
      <div>
        <Masonry breakpointCols={breakpointColumnsObj}>
          {gallery.allFile.nodes.map((el,index) => (
            <Reveal key={index}>
                <GatsbyImage image={el.childImageSharp.gatsbyImageData} loading="lazy" />
            </Reveal>
          ))}
        </Masonry>
      </div>
    </main>
  </>;
}
