import React from 'react'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';

export default function Reveal({children, cc, once, x, y}) {
    const { ref, inView } = useInView({
        threshold: .4,
        triggerOnce: once || true
      });

    const variants = {
        show: {opacity: 1, x:0, y:0, transition: { duration: .8, staggerChildren: .5 } },
        hide: { opacity: 0, x:x, y:y, transition: { duration: .8 } }
    }
    return (
        <motion.div ref={ref} animate={inView ? "show" : "hide"} initial="hide" variants={variants} className={cc}>
            {children} 
        </motion.div>
    )
}
