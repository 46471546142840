import { useStaticQuery, graphql } from "gatsby"

export const useGallery = () => {
  const data = useStaticQuery(
    graphql`query galleryQuery {
  allFile(
    filter: {relativeDirectory: {eq: "gallery"}}
    sort: {order: ASC, fields: name}
  ) {
    nodes {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 400
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
}
`
  )
  return data
}
